import React, { useState, useEffect } from 'react';
import { BiFilter, BiSortDown, BiSortUp } from 'react-icons/bi';
import DashboardHeader from './DashboardHeader';
import { fetchAssignedTrades } from '../../services/assignedServices';
import {
  addCalculatedFieldsToAssignedTrades,
  formatNumber,
} from '../../utils/mathHelpers';
import { useAuth } from '../../context/auth';
import { MdAddTask } from 'react-icons/md';

const AssignedStockHeader = ({
  onSortChange,
  sortField,
  sortAscending,
  handleSortFieldChange,
  toggleSortOrder,
}) => (
  <div className='flex justify-between items-center pb-2'>
    <div className='flex-grow'>
      <h2 className='text-lg font-bold text-gray-700 py-2 uppercase'>
        Assigned Stock
      </h2>
    </div>
    <div className='flex items-center space-x-2'>
      <BiFilter className='text-xl cursor-pointer' />
      <select
        onChange={(e) => handleSortFieldChange(e.target.value)}
        className='cursor-pointer bg-transparent border-none text-md'
      >
        <option value='ticker'>Ticker</option>
        <option value='currentPrice'>Current Price</option>
        <option value='assignedPrice'>Assigned Price</option>
        <option value='currentPnL'>Current P&L</option>
      </select>
      <button
        onClick={toggleSortOrder}
        className='text-gray-700 hover:text-gray-900 transition-colors duration-300'
      >
        {sortAscending ? (
          <BiSortUp className='text-xl' />
        ) : (
          <BiSortDown className='text-xl' />
        )}
      </button>
    </div>
  </div>
);

const AssignedStockItem = ({ ticker, currentPrice, strike, currentPL }) => {
  const isProfitable = currentPL >= 0;
  return (
    <div className='flex justify-between items-center  px-3 py-2 bg-white rounded-lg shadow-sm  text-sm'>
      <span className='w-2/12 px-2 uppercase' title={ticker.toUpperCase()}>{ticker.split(':')[0].toUpperCase()}</span>
      <span className='w-3/12 px-2 text-end'>
        $ {formatNumber(currentPrice)}
      </span>
      <span className='w-3/12 px-2 text-end'>$ {formatNumber(strike, 0)}</span>
      <span
        className={` ${isProfitable ? 'text-green-600' : 'text-red-600'
          } font-medium w-3/12 text-end `}
      >
        {isProfitable
          ? `$ ${formatNumber(currentPL, 0)}`
          : `$ (${formatNumber(-currentPL, 0)})`}
      </span>
    </div>
  );
};

const AssignedStock = () => {
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [sortField, setSortField] = useState('ticker');
  const [sortAscending, setSortAscending] = useState(true);
  const { token, logout } = useAuth();

  const handleSortFieldChange = (field) => {
    setSortField(field);
  };
  const fetchData = async () => {
    try {
      const trades = await fetchAssignedTrades(token);
      const calculatedTrades = addCalculatedFieldsToAssignedTrades(trades);

      setData(calculatedTrades);
    } catch (error) {
      console.error('Error fetching trades:', error);
      if (error.response.data.error === 'Invalid token.') logout();
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);
  const toggleSortOrder = () => {
    setSortAscending(!sortAscending);
  };

  useEffect(() => {
    const sortedList = [...data].sort((a, b) => {
      let valA, valB;

      switch (sortField) {
        case 'ticker':
          valA = a[sortField].toUpperCase();
          valB = b[sortField].toUpperCase();
          break;
        case 'price':
          valA = a['currentPrice'];
          valB = b['currentPrice'];
          break;
        case 'assignedPrice':
          valA = a['strike'];
          valB = b['strike'];
          break;
        case 'currentPL':
          valA = a['currentPL'];
          valB = b['currentPL'];
          break;

        default:
          valA = a[sortField];
          valB = b[sortField];
      }

      if (sortAscending) {
        return valA > valB ? 1 : valA < valB ? -1 : 0;
      } else {
        return valA < valB ? 1 : valA > valB ? -1 : 0;
      }
    });
    setSortedData(sortedList);
  }, [data, sortField, sortAscending]);

  return (
    <div id='assignedStock' className='w-full'>
      <DashboardHeader
        title='Assigned Stock'
        sortOptions={[
          { value: 'ticker', label: 'Ticker' },
          { value: 'currentPrice', label: 'Current Price' },
          { value: 'assignedPrice', label: 'Assigned Price' },
          { value: 'currentPL', label: 'Current P&L' },
        ]}
        sortField={sortField}
        sortAscending={sortAscending}
        handleSortFieldChange={handleSortFieldChange}
        toggleSortOrder={toggleSortOrder}
      />
      <div className='relative flex justify-between items-center px-3 py-2 bg-gray-200/50 rounded-t-lg shadow-sm z-10 text-center text-sm font-semibold'>
        <span className='w-2/12'>Ticker</span>
        <span className='w-3/12'>Current P</span>
        <span className='w-3/12'>Assigned P</span>
        <span className='w-3/12'>Current P&L</span>
      </div>
      <div className='relative mt-1 h-[26rem] max-h-[26rem] overflow-y-auto scrollbar-none'>
        <div className='space-y-2 py-3 h-full'>
          {sortedData.length === 0 ? (
            <div className='flex flex-col items-center justify-center h-full p-4'>
              <MdAddTask size={64} className='text-gray-400' />
              <p className='mt-2 text-sm text-gray-500'>
                No assigned trades in the portfolio page
              </p>
            </div>
          ) : (
            sortedData.map((item, index) => (
              <AssignedStockItem key={index} {...item} />
            ))
          )}
        </div>
        {/* <div className='sticky bottom-0 left-0 w-full h-16 bg-gradient-to-t from-white via-transparent to-transparent pointer-events-none'></div> */}
      </div>
    </div>
  );
};

export default AssignedStock;
