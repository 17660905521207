import React, { useRef, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  LineController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import DashboardHeader from './DashboardHeader';

// Register the required components for ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,

  // Title,
  Tooltip,
  Legend
);

// Create gradient function
function createGradient(ctx, chartArea, colors) {
  const gradient = ctx.createLinearGradient(
    0,
    chartArea.bottom,
    0,
    chartArea.top
  );
  gradient.addColorStop(0, colors.start);
  gradient.addColorStop(1, colors.end);
  return gradient;
}

const PLCharts = ({ title, id, data, gradientColors }) => {
  const chartRef = useRef(null);

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          // pointStyle: id
        },
      },
      title: {
        display: false,
        text: title,
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          callback: function (value) {
            return `$${value}`;
          },
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        ticks: {
          callback: function (value) {
            return value.toFixed(0); // Remove decimals
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  // Process the data to fit Chart.js format
  const processedData = () => ({
    labels: data.map((item) => item.month),
    datasets: [
      {
        type: 'line',
        label: id === 'monthly-pl-chart' ? 'P&L' : 'P&L Cumulative',
        data: data.map((item) =>
          id === 'monthly-pl-chart'
            ? Number(item.pnl.replace(/[^0-9.-]+/g, ''))
            : Number(item.pnlCum.replace(/[^0-9.-]+/g, ''))
        ),
        borderColor: chartRef.current
          ? createGradient(
              chartRef.current.ctx,
              chartRef.current.chartArea,
              gradientColors.line
            )
          : 'rgb(75, 192, 192)',
        borderWidth: 2,
        fill: false,
        yAxisID: 'y',
      },
      {
        type: 'bar',
        label: 'Number of Trades',
        data: data.map((item) => Number(item.trades)),
        backgroundColor: chartRef.current
          ? createGradient(
              chartRef.current.ctx,
              chartRef.current.chartArea,
              gradientColors.bar
            )
          : 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y1',
      },
    ],
  });

  useEffect(() => {
    // Update chart when data changes
    if (chartRef.current) {
      chartRef.current.data = processedData();
      chartRef.current.update();
    }
  }, [data, gradientColors]); // Dependency array includes gradientColors

  return (
    <div id='cumulativePnLChart'>
      <DashboardHeader title={title} />
      <Chart
        ref={chartRef}
        id={id}
        type='bar'
        data={processedData()}
        options={options}
      />
    </div>
  );
};

export default PLCharts;
