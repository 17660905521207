import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import DashboardHeader from './DashboardHeader'; // Make sure this path is correct

ChartJS.register(ArcElement, Tooltip, Legend);

const ActiveTrades = ({ activeTrades }) => {
  const [filter, setFilter] = useState('both'); // Initialize filter to 'both'
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    const labels = [];
    const data = [];
    console.log(activeTrades)
    // Aggregate data based on the filter
    Object.entries(activeTrades).forEach(([sector, counts]) => {
      // Map "undefined" string key to "Others"
      const sectorName = sector === "undefined" || sector === undefined || sector === null || sector === ""
        ? "Others"
        : sector;

      labels.push(sectorName);

      if (filter === 'both') {
        data.push(counts.puts + counts.calls);
      } else {
        data.push(counts[filter]);
      }
    });


    setChartData({
      labels,
      datasets: [{
        label: 'Number of Trades',
        data: data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
        ],
        borderColor: [
          'white',
        ],
        borderWidth: 1,
      }]
    });
  }, [filter, activeTrades]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      // title: {
      //   display: true,
      //   text: 'Active Trades by Sector',
      // },
    },
  };

  return (
    <div id='activeTradesBySector' className='mb-8 w-full'>
      <DashboardHeader
        title="ACTIVE TRADES BY MARKET SECTOR"
        sortOptions={[
          { value: 'both', label: 'Both' },
          { value: 'puts', label: 'Puts' },
          { value: 'calls', label: 'Calls' },
        ]}
        sortField={filter}
        sortAscending={true} // This prop might not be applicable in this context
        handleSortFieldChange={setFilter} // Directly set the filter based on selection
        toggleSortOrder={() => { }} // Placeholder function, modify as needed
      />
      <Pie data={chartData} options={options} />
    </div>
  );
};

export default ActiveTrades;
