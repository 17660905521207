import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const Tooltip = ({
  icon,
  content, // Main content to display in the tooltip
  type, // 'error' or other types
  position = 'bottom',
  color = 'bg-red-600',
  tooltipClass = '',
  tooltipStyle = {},
}) => {
  const iconRef = useRef(null);
  const tooltipRef = useRef(null);
  const [tooltipPositionStyle, setTooltipPositionStyle] = useState({});
  const [showTooltip, setShowTooltip] = useState(type === 'error');

  const updateTooltipPosition = () => {
    if (iconRef.current && tooltipRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      const isSmallScreen = window.innerWidth <= 768;

      let newPositionStyle = {};

      switch (position) {
        case 'bottom':
          newPositionStyle = {
            ...tooltipStyle,
            position: 'absolute',
            top: `${rect.bottom + window.scrollY}px`,
            left: isSmallScreen ? '0px' : `${rect.left}px`,
            maxWidth: isSmallScreen ? '100%' : 'auto',
          };
          break;
        // Add cases for other positions as needed
        default:
          break;
      }

      // Only update state if the new position is different
      if (
        JSON.stringify(tooltipPositionStyle) !== JSON.stringify(newPositionStyle)
      ) {
        setTooltipPositionStyle(newPositionStyle);
      }
    }
  };

  useLayoutEffect(() => {
    if (showTooltip && content) {
      updateTooltipPosition();
    }
  }, [showTooltip, content, position]);

  useEffect(() => {
    if (showTooltip) {
      window.addEventListener('resize', updateTooltipPosition);
      document.addEventListener('click', handleClickOutside);
    } else {
      window.removeEventListener('resize', updateTooltipPosition);
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('resize', updateTooltipPosition);
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showTooltip]);

  const handleClickOutside = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      iconRef.current &&
      !iconRef.current.contains(event.target)
    ) {
      if (type !== 'error') {
        setShowTooltip(false);
      }
    }
  };

  const handleIconClick = () => {
    if (type !== 'error') {
      setShowTooltip(!showTooltip);
    }
  };

  const renderTooltip = () => {
    if (!content || !showTooltip) return null;

    return ReactDOM.createPortal(
      <div
        id="tooltip-description"
        role="tooltip"
        className={`z-[9999] p-2 text-sm text-white rounded-md shadow-lg transition-opacity duration-300 ease-in ${color} ${tooltipClass}`}
        style={tooltipPositionStyle}
        ref={tooltipRef}
      >
        {content}
      </div>,
      document.body
    );
  };

  return (
    <div
      className={`cursor-pointer flex items-center justify-center`}
      ref={iconRef}
      onClick={handleIconClick}
    >
      {icon}
      {renderTooltip()}
    </div>
  );
};

export default Tooltip;
