import { MdRemoveCircleOutline } from 'react-icons/md';
import { DateCell, TickerCell } from '../utils/tableHandlers';
import { useMemo } from 'react';
import { useAuth } from '../context/auth';
import Tooltip from '../components/Tooltip';
import { calculateDaysToExpiration, formatNumber } from '../utils/mathHelpers';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { getBgColor } from '../utils/stylingHelpers';

export const WheelIncomePutsColumns = ({
  handleDelete,
  updateData,
  isDecayVisible,
  footerSums,

  sellingType,
}) => {
  return useMemo(() => [
    {
      id: 'delete',
      accessorKey: 'delete',
      header: <h6 className='block w-full h-6'></h6>,
      cell: (info) => (
        <>
          {info.row.original._id && (
            <button
              className='px-2 py-1 w-full flex justify-center items-center'
              onClick={() => handleDelete(info.row)}
            >
              <MdRemoveCircleOutline className='w-5 h-5 fill-red-600' />
            </button>
          )}
        </>
      ),
      size: 50,
      maxSize: 100,
      minSize: 30,
      enableResizing: true,
    },
    {
      id: 'WheelIncomeName',
      header: (
        <h6 className='px-4 py-1 w-full text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Company Name
        </h6>
      ),
      accessorKey: 'WheelIncomeName',
      cell: (info) => {
        return (
          <div className={`px-4 py-1 flex justify-between space-x-2 w-full`}>
            <h6
              className={` ${info.row.getValue('WheelIncomeName') === 'Pending...'
                ? 'text-gray-500 italic'
                : info.row.getValue('WheelIncomeName')
                  ? ''
                  : 'text-red-500 italic'
                } flex-1 text-left `}
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              {info.row.getValue('WheelIncomeName')
                ? info.row.getValue('WheelIncomeName')
                : info.row.original._id
                  ? 'Not found'
                  : ''}
            </h6>
            <Tooltip
              icon={
                <HiQuestionMarkCircle
                  className={`w-5 h-5 ${info.row.getValue('WheelIncomeName') === 'Pending...'
                    ? 'fill-[#ffc954]'
                    : info.row.getValue('WheelIncomeName')
                      ? 'fill-[#3e63decc]'
                      : info.row.original._id
                        ? 'fill-[#ff3737]'
                        : 'fill-[#949494]'
                    }`}
                />
              }
              content={
                info.row.getValue('WheelIncomeName') === 'Pending...'
                  ? 'Pending...'
                  : !info.row.original._id
                    ? 'Please type a Ticker'
                    : info.row.getValue('WheelIncomeName')
                      ? info.row.original.description
                      : 'Not found'
              }
              type='text'
              color={`${info.row.getValue('WheelIncomeName') === 'Pending...'
                ? 'bg-[#ffc954]'
                : info.row.getValue('WheelIncomeName')
                  ? 'bg-[#6582e5]'
                  : info.row.original._id
                    ? 'bg-red-600'
                    : 'bg-gray-400'
                }`}
              tooltipClass='max-w-md whitespace-normal break-words'
              tooltipStyle={{
                border: `3px solid  ${info.row.getValue('WheelIncomeName') === 'Pending...'
                  ? '#ffc924'
                  : info.row.getValue('WheelIncomeName')
                    ? '#3e64de'
                    : info.row.original._id
                      ? '#ff3737'
                      : '#949494'
                  }`,
                width: '300px',
                maxHeight: '200px',
                overflowY: 'auto',
                zIndex: '999',
              }}
            />
          </div>
        );
      },
      size: 150,
      maxSize: 250,
      minSize: 120,
    },
    {
      id: 'WheelTicker',
      header: (
        <h6
          id='tickerHeader'
          className='px-4 py-1 w-full sticky text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '
        >
          ticker
        </h6>
      ),
      accessorKey: 'WheelTicker',
      cell: (info) => {
        return (
          <TickerCell
            updateData={updateData}
            row={info.row}
            columnId={'WheelTicker'}
            page='wheelIncome'
            sellingType={sellingType}
          />
        );
      },
      size: 100,
      maxSize: 150,
      minSize: 90,
    },
    {
      id: 'WheelCurrentPrice',
      accessorKey: 'WheelCurrentPrice',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Current $
        </h6>
      ),
      cell: (info) => (
        <span
          className={`w-full px-4 py-1 flex items-center justify-end text-end`}
        >
          {info.getValue() && `$ ${formatNumber(info.getValue())}`}
        </span>
      ),
      size: 125,
      maxSize: 150,
      minSize: 100,
    },
    {
      id: 'WheelSector',
      accessorKey: 'WheelSector',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Sector
        </h6>
      ),
      cell: (info) => {
        return (
          <span className='w-full px-4 py-1 flex items-center  font-medium'>
            {info.getValue()}
          </span>
        );
      },
      size: 125,
      maxSize: 150,
      minSize: 100,
    },
    {
      id: 'WheelIndustry',
      accessorKey: 'WheelIndustry',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          industry
        </h6>
      ),
      cell: (info) => {
        return (
          <span className='w-full px-4 py-1 flex items-center justify-start  font-medium'>
            {info.getValue()}
          </span>
        );
      },
      size: 125,
      maxSize: 150,
      minSize: 100,
    },

    {
      id: 'optionStrikePrice',
      accessorKey: 'optionStrikePrice',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Option strike $
        </h6>
      ),
    },
    {
      id: 'dropPercent',
      accessorKey: 'dropPercent',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          drop %
        </h6>
      ),
      cell: (info) => {
        // Assuming info.row has the current stock price and the put option strike price
        const currentPrice = info.row.getValue('WheelCurrentPrice');
        const strikePrice = info.row.getValue('optionStrikePrice');
        const dropPercentage =
          currentPrice && strikePrice
            ? ((currentPrice - strikePrice) / currentPrice) * 100
            : null;

        return (
          <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
            {dropPercentage !== null ? `${Math.round(dropPercentage)}%` : null}
          </span>
        );
      },
      size: 100,
      maxSize: 200,
      minSize: 50,
    },
    {
      id: 'WheelOptionPremium',
      accessorKey: 'WheelOptionPremium',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Option prem
        </h6>
      ),
      size: 100,
      maxSize: 200,
      minSize: 50,
    },
    {
      id: 'dateSoldOption',
      accessorKey: 'dateSoldOption',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          date Sold Option
        </h6>
      ),
      cell: (info) => {
        return (
          <DateCell
            updateData={updateData}
            row={info.row}
            columnId={'dateSoldOption'}
            page='WheelIncome'
            sellingType={sellingType}
          />
        );
      },
      size: 135,
      maxSize: 200,
      minSize: 130,
    },
    {
      id: 'WheelExpDate',
      accessorKey: 'WheelExpDate',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Expiration date
        </h6>
      ),
      cell: (info) => {
        return (
          <DateCell
            updateData={updateData}
            row={info.row}
            columnId={'WheelExpDate'}
            page='WheelIncome'
            sellingType={sellingType}
          />
        );
      },
      size: 135,
      maxSize: 200,
      minSize: 130,
    },

    {
      id: 'wheelDaysToExpiration',
      accessorKey: 'wheelDaysToExpiration',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Days to exp
        </h6>
      ),
      cell: (info) => {
        const daysToExpiration = calculateDaysToExpiration(
          info.row.original.WheelExpDate,
          'UTC',
          info.row.original.dateSoldOption
        );

        return (
          <div className={`px-4 py-1  flex items-center justify-center w-full`}>
            <span className='text-sm'>
              {daysToExpiration === 'Expired'
                ? 'Expired'
                : daysToExpiration === null
                  ? ''
                  : `${daysToExpiration} days`}
            </span>
          </div>
        );
      },
      size: 125,
      maxSize: 150,
      minSize: 100,
    },
    {
      id: 'optionsToTrade',
      accessorKey: 'optionsToTrade',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          NB OPTIONS
        </h6>
      ),
      size: 125,
      maxSize: 150,
      minSize: 100,
    },
    {
      id: 'PremiumCollected',
      accessorKey: 'PremiumCollected', // Accessor for the value from your row data
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Prem collected
        </h6>
      ),
      cell: (info) => {
        // Assuming that info.row has the number of options and the premium per option
        const numberOfOptions = info.row.getValue('optionsToTrade');
        const premium = info.row.getValue('WheelOptionPremium');
        const premiumCollected =
          numberOfOptions && premium
            ? numberOfOptions * premium * 100 // Each option usually represents 100 shares
            : 0; // Default to 0 if values are not present

        return (
          <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
            {`$ ${formatNumber(premiumCollected)}`}
          </span>
        );
      },

      size: 125,
      maxSize: 150,
      minSize: 100,
    },
    {
      id: 'PremiumPerDay',
      accessorKey: 'PremiumPerDay',
      header: (
        <h6 className='px-4 py-1 text-center text-green-900 whitespace-nowrap text-ellipsis text-xs font-bold uppercase tracking-wider '>
          Prem per day
        </h6>
      ),
      cell: (info) => {
        // Assuming info.row has the Prem collected and days to expiration
        const numberOfOptions = info.row.getValue('optionsToTrade');
        const premium = info.row.getValue('WheelOptionPremium');
        const premiumCollected =
          numberOfOptions && premium ? numberOfOptions * premium * 100 : 0; // Each option usually represents 100 shares

        // Use the calculateDaysToExpiration function to get the days to expiration
        const daysToExpiration = calculateDaysToExpiration(
          info.row.original.WheelExpDate,
          'UTC',
          info.row.original.dateSoldOption
        );

        // Check if daysToExpiration is "Expired", a negative value, or null before calculating premiumPerDay
        let premiumPerDay = 0;
        if (
          daysToExpiration &&
          daysToExpiration !== 'Expired' &&
          daysToExpiration > 0
        ) {
          premiumPerDay = premiumCollected / daysToExpiration;
        }

        // Get the background color based on some condition, assuming getBgColor is a function that returns a color class name
        const bgColorClass = getBgColor('PremiumPerDay');

        return (
          <span
            className={`w-full px-4 py-1 ${bgColorClass} flex items-center text-green-900 font-bold justify-end text-end`}
          >
            {premiumPerDay > 0
              ? `$ ${formatNumber(premiumPerDay, 0)}`
              : daysToExpiration === 'Expired'
                ? 'Expired'
                : 0}
          </span>
        );
      },

      size: 125,
      maxSize: 150,
      minSize: 100,
    },
    {
      id: 'neededToBuyStock',
      accessorKey: 'neededToBuyStock',
      header: (
        <h6 className='px-4 py-1 text-center text-red-900 whitespace-nowrap text-ellipsis text-xs font-bold uppercase tracking-wider '>
          $ needed To Buy Stock
        </h6>
      ),
      cell: (info) => {
        // Assuming info.row has the put option strike price and the number of NB OPTIONS
        const putOptionStrikePrice = info.row.getValue('optionStrikePrice');
        const optionsToTrade = info.row.getValue('optionsToTrade');

        // Calculate the total amount needed to buy stock if assigned
        const neededToBuyStock =
          putOptionStrikePrice && optionsToTrade
            ? putOptionStrikePrice * optionsToTrade * 100 // Each option usually represents 100 shares
            : 0;

        // Get the background color based on some condition, assuming getBgColor is a function that returns a color class name
        const bgColorClass = getBgColor('neededToBuyStock');

        return (
          <span
            className={`w-full px-4 py-1 flex text-red-900 font-bold items-center ${bgColorClass} justify-end text-end`}
          >
            {`$ ${formatNumber(neededToBuyStock, 0)}`}
          </span>
        );
      },
      footer: () => (
        <div className='  flex flex-col '>
          <div
            className={` relative   flex justify-end items-center text-end h-8 font-medium `}
          >
            <p className='px-4 py-1 absolute whitespace-nowrap font-bold text-red-900 w-full '>
              ${193839}
            </p>
          </div>
          <div
            className={` relative   flex justify-end items-center text-end h-8 font-medium `}
          >
            <p className='px-4 py-1 absolute whitespace-nowrap font-bold bg-red-300 text-red-900 w-full '>
              ${193839}
            </p>
          </div>
          <div
            className={` relative   flex justify-end items-center text-end h-8 font-medium `}
          >
            <p className='px-4 py-1 absolute whitespace-nowrap font-bold text-red-900 w-full '>
              ${193839}
            </p>
          </div>
        </div>
      ),
      size: 125,
      maxSize: 150,
      minSize: 100,
    },
    {
      id: 'PremiumAnnualized',
      accessorKey: 'PremiumAnnualized',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Prem annualized
        </h6>
      ),
      cell: (info) => {
        // Assuming info.row has the Prem collected, needed to buy stock, and days to expiration
        const optionsToTrade = info.row.getValue('optionsToTrade');
        const premium = info.row.getValue('WheelOptionPremium');
        const premiumCollected =
          optionsToTrade && premium
            ? optionsToTrade * premium * 100 // Each option usually represents 100 shares
            : 0; // Default to 0 if values are not present

        const putOptionStrikePrice = info.row.getValue('optionStrikePrice');

        // Calculate the total amount needed to buy stock if assigned
        const neededToBuyStock =
          putOptionStrikePrice && optionsToTrade
            ? putOptionStrikePrice * optionsToTrade * 100 // Each option usually represents 100 shares
            : 0;
        const daysToExpiration = calculateDaysToExpiration(
          info.row.original.WheelExpDate,
          'UTC',
          info.row.original.dateSoldOption
        );

        // If days to expiration is 0, expired, or null, annualized premium cannot be calculated
        const isExpiredOrInvalid =
          !daysToExpiration ||
          daysToExpiration <= 0 ||
          daysToExpiration === 'Expired';

        // Calculate the Prem annualized, typically:
        // ( (premiumCollected / daysToExpiration) * 365 ) / (neededToBuyStock) * 100
        const premiumAnnualized =
          !isExpiredOrInvalid && neededToBuyStock
            ? (((premiumCollected / daysToExpiration) * 360) /
              neededToBuyStock) *
            100
            : 0;

        return (
          <span
            className={`w-full px-4 py-1 flex items-center justify-end text-end`}
          >
            {premiumAnnualized > 0
              ? `${formatNumber(premiumAnnualized, 0)}%`
              : daysToExpiration === 'Expired'
                ? 'Expired'
                : ''}
          </span>
        );
      },

      size: 125,
      maxSize: 150,
      minSize: 100,
    },
  ]);
};
