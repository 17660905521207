import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { MdOutlineCopyAll } from "react-icons/md";
import { useAuth } from '../context/auth';
import { searchTickers } from '../services/searchedTickers';
import { toast } from 'react-toastify';

const categories = ['All', 'Stock', 'ETF', 'Index'];

const TickerSearchBar = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({
    stock: [],
    ETF: [],
    index: [],
  });
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuth();

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  // Function to handle API call
  const fetchTickers = async (searchQuery) => {
    setIsLoading(true);
    try {
      const tickerResults = await searchTickers(searchQuery, token);
      setResults(tickerResults);
    } catch (error) {
      toast.error('Error fetching tickers. Please try again later.');
    }
    setIsLoading(false);
  };

  // Debounce the search query
  useEffect(() => {
    if (query.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        fetchTickers(query);
        setIsDropdownOpen(true);
        setSelectedCategory('All'); // Reset category on new search
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setIsDropdownOpen(false);
    }
  }, [query]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, inputRef]);

  // Copy ticker to clipboard
  const copyToClipboard = (ticker) => {
    navigator.clipboard.writeText(ticker).then(() => {
      toast.info('Copied to clipboard');
      setIsDropdownOpen(false); // Close dropdown when item is copied
    });
  };

  // Get filtered results based on selected category
  const getFilteredResults = () => {
    if (selectedCategory === 'All') {
      return [...results.stock, ...results.ETF, ...results.index];
    }
    return results[selectedCategory.toLowerCase()] || [];
  };

  const filteredResults = getFilteredResults();

  // Render dropdown using portal
  const renderDropdown = () => {
    if (!isDropdownOpen) return null;

    const inputRect = inputRef.current.getBoundingClientRect();

    return ReactDOM.createPortal(
      <div
        id="ticker-search-dropdown" // Add ID for Driver.js
        ref={dropdownRef}
        className="absolute z-[10000] w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-80 overflow-auto"
        style={{
          top: `${inputRect.bottom + window.scrollY}px`,
          left: `${inputRect.left}px`,
          width: `${inputRect.width}px`,
        }}
      >
        {/* Category Tabs */}
        <div id="category-tabs" className="sticky top-0 bg-white p-2 border-b border-gray-200 z-[10000]">
          <div className="flex justify-center">
            {categories.map((category) => (
              <button
                key={category}
                id={`category-${category.toLowerCase()}`} // Unique ID for each category
                className={`px-4 py-2 border-b-2 ${selectedCategory === category ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-500'} focus:outline-none whitespace-nowrap`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {isLoading ? (
          <p className="text-sm text-gray-500 p-4 text-center">Loading...</p>
        ) : filteredResults.length > 0 ? (
          filteredResults.map((result,index) => (
            <li
              key={result.symbol}
              id={`result-${index}`} // Add ID for each result item
              className="flex result justify-between items-center px-4 py-3 hover:bg-gray-100 cursor-pointer"
              onClick={() => copyToClipboard(`${result.symbol}`)}
            >
              <div className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2">
                  <span className="font-semibold text-lg">{result.symbol}</span>
                  <span className="text-sm text-gray-500">{result.country_code}</span>
                </div>
                <span className="text-sm text-gray-600">{result.name}</span>
              </div>
              <MdOutlineCopyAll className="w-5 h-5 text-gray-500 hover:text-blue-500" />
            </li>
          ))
        ) : (
          <p className="text-center py-4 font-bold text-gray-700">No symbol found</p>
        )}
      </div>,
      document.body
    );
  };

  return (
    <div className="relative w-full max-w-lg mx-auto z-[9999]">
      <input
        type="text"
        id="ticker-search-input" // Add ID for Driver.js
        ref={inputRef}
        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Search for ticker symbols..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={() => setIsDropdownOpen(true)}
      />

      {/* Render dropdown using a portal */}
      {renderDropdown()}
    </div>
  );
};

export default TickerSearchBar;
