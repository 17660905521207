import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';

export const guidedTutorialHandler = (guidedSteps) => {
  // then add a click handler or however you
  // might want to trigger it and initialize the Driver
  const driverObj = driver({
    showProgress: true,
    steps: guidedSteps,
  });
  driverObj.drive();
};

export const guidedTutorialHistoryHandler = (isOpenDrawer, setIsOpenDrawer) => {
  const driverObj = driver({
    showProgress: true,
    steps: [
      // Highlight the entire table
      {
        element: '#assignedStockTable', // Replace with the actual selector for the history table
        popover: {
          title: 'Trading History Overview',
          description:
            'This is your TRADING HISTORY screen. Here you can review all past trades and analyze your trading patterns over time. It is an essential tool for reflecting on your trading strategies and outcomes.',
          popoverClass:
            'bg-white text-gray-700 border border-gray-300 shadow-lg max-w-xs rounded-lg p-4',
          side: 'center',
          align: 'center',
        },
      },
      // Step 1: Highlight the toolbar
      {
        element: '#toolbar', // Replace with the actual selector for the toolbar
        popover: {
          title: 'Toolbar Functions',
          description:
            'The toolbar offers several functionalities to enhance your experience: search through history records, adjust column visibility, and sort data.',
          side: 'bottom',
          align: 'start',
        },
      },
      // Highlight the search bar
      {
        element: '#searchBar', // Replace with the actual selector for the search bar
        popover: {
          title: 'Search Your History',
          description:
            'Utilize the search function to find specific trades quickly.',
          side: 'bottom',
          align: 'start',
        },
      },
      // Step 3: Highlight the columns toggle button
      {
        element: '#columnsToggle', // Replace with the actual selector for the columns toggle button
        popover: {
          title: 'Customize Columns',
          description:
            'Customize your view by choosing which columns to display.',
          side: 'bottom',
          align: 'start',
        },
      },
      // Step 4: Highlight the refresh data button
      {
        element: '#refreshTable', // Replace with the actual selector for the refresh button
        popover: {
          title: 'Update Historical Data',
          description:
            'Use this button to refresh and get the most recent updates to your trading history.',
          side: 'bottom',
          align: 'start',
        },
      },
      // Step 5: Highlight the tutorial link
      {
        element: '#youtubeTutorial', // Replace with the actual selector for the tutorial link
        popover: {
          title: 'Learn with Tutorials',
          description:
            'Click here for tutorials that provide detailed instructions and tips for using the history feature effectively.',
          side: 'top',
          align: 'center',
        },
      },
      // Step 6: Highlight the color key
      {
        element: '#showColorKeyMap', // Replace with the actual selector for the color key map
        popover: {
          title: 'Color Key Map',
          description:
            'The color key map is a visual aid for quickly understanding the status and results of your past trades.',
          position: 'bottom',
          align: 'center',
        },
      },
      // Step 7: Highlight the performance section
      {
        element: '#tableContainer', // Replace with the actual selector for the performance section
        popover: {
          title: 'Performance Insights',
          description:
            'This section displays the performance of your trades, helping you to assess your strategy’s effectiveness and make data-driven decisions.',
          side: 'top',
          align: 'center',
        },
      },
      // Step 8: Highlight the dynamic table functionality
      {
        element: '#tickerHeader', // Replace with the actual selector for the dynamic table functionality
        popover: {
          title: 'Interactive Table Features',
          description:
            'Explore the dynamic features of the table such as sorting, resizing columns, and editing entries for an optimized review of your trade history.',
          side: 'top',
          align: 'center',
        },
      },

      {
        element: '#drawerToggleButton',
        popover: {
          title: 'Analytics Drawer',
          description:
            'Use this button to toggle the analytics drawer for additional insights and historical data analysis.',

          onNextClick: () => {
            if (!isOpenDrawer) {
              setIsOpenDrawer(true); // Open the drawer
            }
            setTimeout(() => {
              // Wait for the drawer to open before proceeding to the next step
              driverObj.moveNext();
            }, 300);
            // Proceed to the next step immediately.
            // driverObj.moveNext();
          },
        },
      },

      // Step to highlight the overview inside the drawer
      {
        element: '#drawerOverview', // Replace with the actual selector for the overview section inside the drawer
        popover: {
          title: 'Overview Section',
          description:
            'This section gives you a summary of your trading history and an overview of your performance metrics.',
          position: 'right',
          align: 'top',
          onPrevClick: (el) => {
            // if (isOpenDrawer) {
            setIsOpenDrawer(false); // Open the drawer
            // }
            // Proceed to the next step immediately.
            setTimeout(() => {
              // Wait for the drawer to open before proceeding to the next step
              driverObj.movePrevious();
            }, 300);
          },
        },
      },

      // Step to highlight the year dropdown inside the drawer
      {
        element: '#yearDropdown', // Replace with the actual selector for the year dropdown
        popover: {
          title: 'Year Selection',
          description:
            'Select the year to filter your trading history data within the drawer.',
          // position: 'right',
          // align: 'top',
        },
      },

      // Steps for each chart inside the drawer
      {
        element: '#monthly-pl-chart', // Replace with the actual selector for the monthly P&L chart
        popover: {
          title: 'Monthly P&L Chart',
          description:
            'This chart displays your profit and loss distribution on a monthly basis for the selected year.',
          // position: 'right',
          // align: 'top',
        },
      },
      {
        element: '#cumulative-pl-chart', // Replace with the actual selector for the cumulative P&L chart
        popover: {
          title: 'Cumulative P&L Chart',
          description:
            'Here you can see your cumulative profit and loss over the months for the selected year.',
          // position: 'right',
          // align: 'top',
        },
      },

      // Step to highlight the P&L summary table inside the drawer
      {
        element: '#plSummaryTable', // Replace with the actual selector for the P&L summary table
        popover: {
          title: 'P&L Summary Table',
          description:
            'This table summarizes your profit and loss, ROI, and other important metrics for each month.',
          // position: 'right',
          // align: 'top',
        },
      },
    ],
  });

  // const steps =

  // // Define steps in the Driver instance
  // driverObj.defineSteps(steps);

  // Optionally, adjust for when the drawer closes and you need to manually trigger a previous step
  // onDrawerClose(() => driverObj.prev());

  // Start the tour
  driverObj.drive();
};


export const guidedTickerHandler = () => {
  const driverObj = new driver({
    showProgress: true,
    overlayClickNext: true,
    animate: true,
    steps: [
      {
        element: '#ticker-search-input',
        popover: {
          title: 'Search for Ticker',
          description: 'Type at least 3 characters (e.g., "AAPL") to search for stocks, ETFs, or indices.',
          position: 'bottom',
          showButtons: ['next', 'close'], // Show only "Next" and "Close" buttons
          disableButtons: ['next'], // Disable "Next" button initially
        },
        onHighlighted: () => {
          const nextButton = document.querySelector('.driver-popover-next-btn');
          const inputField = document.querySelector('#ticker-search-input');

          console.log('inputField', inputField); // Check if input field is being found

          if (nextButton) {
            // Disable the "Next" button by default
            nextButton.classList.add('disabled');
            nextButton.style.pointerEvents = 'none';
          }

          // Event listener to enable "Next" button when 3+ characters are entered
          const handleInputChange = () => {
            if (inputField.value.length >= 3) {
              // Enable the "Next" button
              nextButton.classList.remove('disabled');
              nextButton.classList.remove('driver-popover-btn-disabled');
              nextButton.removeAttribute('disabled');
              nextButton.style.pointerEvents = 'auto';
            } else {
              // Disable the "Next" button if input is less than 3 characters
              console.log(nextButton);
              //<button type="button" class="driver-popover-next-btn driver-popover-btn-disabled disabled" disabled="" style="display: block; pointer-events: none;">Next →</button>
              // nextButton.removeAttribute('disabled');
              nextButton.classList.add('disabled');
              nextButton.classList.add('driver-popover-btn-disabled');
              nextButton.style.pointerEvents = 'none';
            }
          };

          if (inputField) {
            inputField.addEventListener('input', handleInputChange);
          }

          // Clean up the event listener when moving to the next step
          // driverObj.once('next', () => {
          //   if (inputField) {
          //     inputField.removeEventListener('input', handleInputChange);
          //   }
          // });
        },
      },
      {
        element: '#category-tabs',
        popover: {
          title: 'Filter by Category',
          description: 'Use these tabs to filter results by category: Stock, ETF, or Index.',
          position: 'bottom',
        },
      },
      {
        element: '#ticker-search-dropdown',
        popover: {
          title: 'View Search Results',
          description: 'Here are the matching results for your search. Click on a result to copy it.',
          position: 'bottom',
          onNextClick: () => {
            const dropdown = document.querySelector('#ticker-search-dropdown');
            if (dropdown && dropdown.children.length > 0) {
              return true; // Allow proceeding if search results are visible
            } else {
              alert('Please enter a valid ticker symbol to see results.');
              return false;
            }
          },
        },
      },
      // {
      //   element: '#result-0', // Replace with the actual ID of a result item
      //   popover: {
      //     title: 'Copy a Ticker',
      //     description: 'Click on a ticker symbol to copy it to your clipboard. Use this symbol for trade entries.',
      //     position: 'right',
      //   },
      // },
    ],
  });

  // Start the guided tour
  driverObj.drive();
};
