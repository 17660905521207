import React, { useCallback, useEffect, useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import { createTrade, deleteTradeById, updateTradeCellByRowId, } from '../services/tradesServices';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Tooltip from '../components/Tooltip';
import { MdCalendarMonth, MdError } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useAuth } from '../context/auth';
import DatePicker from 'react-datepicker';
import { IoMdRefreshCircle } from 'react-icons/io';
import { NumericFormat } from 'react-number-format';
import { useTradeStatus } from '../hooks/useTradeStatus';
import { formatGroupTitle, getTradeStatus } from './tableHelpers';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { format, formatISO, startOfDay } from 'date-fns';
import { addToWatchList, deleteWatchListById, updateWatchCellByRowId } from '../services/watchListServices';
import { getBgColor, getDateCellStyles, getDropdownStyles, getFinalPLStyles, getFontColor, getTickerStyles } from './stylingHelpers';
import { onSubmit } from './tableHelpers/bodyHandlers';
import { calculateDaysToExpiration, calculateFinalPL } from './mathHelpers';
import { addManualTrade, deleteManualTradeById } from '../services/historyTradesSevices';
import { FaUndo, FaLock } from 'react-icons/fa';
import { createWheelTrade, deleteWheelIncomeById, updateWheelIncomeCellByRowId } from '../services/WheelIncomeServices';
import { deleteBreakEvenOperation } from '../services/breakevenServices';


const ErrorIcon = ({ message, name, type }) => (
  <Tooltip
    type={type}
    icon={
      <button
        data-tooltip-target='tooltip-hover'
        data-tooltip-trigger='hover'
        type='button'
        className={` text-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-full`}
      >
        <MdError data-tooltip-target={name} className='w-5 h-5' color='red' />
      </button>
    }
    content={message}
  />
);

export const ColorCell = ({
  columnId,
  updateData,
  row,
  token,
}) => {

  const value = row.getValue('color')
  const rowId = row.original._id
  const rowIndex = row.index
  const name = `color-${columnId}-${rowId || rowIndex}`;
  const [showDropdown, setShowDropdown] = useState(false);

  // const [localValue, setLocalValue] = useState(value);
  const predefinedColors = [
    '#26547C', // Dusk Blue
    '#EF476F', // Faded Jade
    '#FFD166', // Golden Hour
    '#06D6A0', // Sea Foam Green
    '#F07167', // Pastel Red
    '#6B2A69', // Cyber Grape
    '#1E90FF', // Iris Blue
    '#50514F', // Mint Cream
  ];

  const schema = yup.object({
    [name]: yup.string().required('Color is required'),
  });

  const { register, setValue, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const newValue = data[name];
    //  const newValue = e.target.value;
    try {
      if (rowId) {
        await updateTradeCellByRowId({ row, columnId, newValue, token });
        updateData({ rowIndex, rowId, columnId, value: newValue });
      } else {
        updateData({ rowIndex, rowId, columnId, value: newValue });
      }
    } catch (error) {
      console.error('Error updating dropdown cell:', error);
    }
  };

  useEffect(() => {
    setValue(name, value);
    // setLocalValue(value); // Synchronize local value with parent value
  }, [value, setValue, name]);

  return (
    <div className='px-2 py-0 flex justify-center items-center relative'>
      <div
        className='w-4 h-4 rounded cursor-pointer z-10'
        style={{ backgroundColor: value }}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {/* Trigger */}
      </div>

      {showDropdown && (
        <div className=' fixed bg-white  gap-3 z-50 h-32 overflow-y-scroll border rounded'>
          {predefinedColors.map((color, index) => (
            <div
              key={index}
              className='w-8 h-4 cursor-pointer shadow-lg rounded mx-1 mt-2 last:mb-2
               '
              style={{ backgroundColor: color }}
              onClick={() => {
                setValue(name, color);
                handleSubmit(onSubmit)();
                setShowDropdown(false);
              }}
            >
              {/* Rectangle */}
            </div>
          ))}
        </div>
      )}

      <input id={name} {...register(name)} type='hidden' value={value} />
    </div>
  );
};

// Dropdown Cell
export const DropdownCell = ({
  options,
  updateData,
  columnId,
  row,
  page
}) => {
  // // const rowId = row.original._id
  // const rowIndex = row.index
  const value = row.getValue(columnId)

  const { token } = useAuth();
  const [currentValue, setCurrentValue] = useState(value);

  const handleSubmit = async (newValue) => {
    if (newValue !== value) {
      await onSubmit({
        row,
        columnId,
        newValue,
        token,
        updateData,
        currentValue: value,
        page
      });
      if (row.original.closingDate === null && columnId === 'closingTradeType') {
        const tradeDate = new Date()
        await onSubmit({
          row,
          columnId: 'closingDate',
          newValue: tradeDate,
          token,
          updateData,
          currentValue: null,
        });
      }
      if (row.original.closingDate !== null && !newValue && columnId === 'closingTradeType') {
        await onSubmit({
          row,
          columnId: 'closingDate',
          newValue: null,
          token,
          updateData,
          // currentValue: null,
        });
      }

    }
  };
  useEffect(() => {
    setCurrentValue(value); // Synchronize local value with parent value
  }, [value]);

  const tradeStatus = page === 'portfolio' ? getTradeStatus(row).value : null;
  const backgroundColor = getDropdownStyles(page, columnId, row.getValue(columnId), tradeStatus);
  const fontColor = getFontColor(columnId, row.getValue(columnId)); // Assuming this logic remains the same across pages

  return (
    <select
      className={`px-4 py-1 w-full h-[28px] ${page === 'breakEven' ? 'bg-yellow-300' : backgroundColor} ${fontColor} border-none focus:outline-none `}
      value={currentValue}
      onChange={(e) => {
        setCurrentValue(e.target.value);
        handleSubmit(e.target.value);
      }}
    >
      {<option value=''>
        Select One...
      </option>}
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export const DateCell = ({
  updateData,
  columnId,
  row,
  page,
  sellingType
}) => {
  const rowId = row.original._id
  const rowIndex = row.index
  const value = row.getValue(columnId)
  const { token } = useAuth()
  // Use the user's timezone for date manipulation and formatting
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formatDate = (dateString) => {
    const date = utcToZonedTime(new Date(dateString), timezone); // Convert UTC to user's timezone
    return format(date, "dd MMM ''yy", { timeZone: timezone });
  };

  const name = `date-${columnId}-${rowId || rowIndex}`;
  const schema = yup.object({
    // [name]: yup.date().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    register(name);
    setValue(name, value ? utcToZonedTime(new Date(value), timezone) : ''); // Convert UTC to user's timezone
    if (errors[name]) {
      toast.error(errors[name].message);
    }
  }, [register, setValue, value, name, errors, timezone]);

  const onSubmit = async (data) => {
    try {
      const dateValue = data[name] ? zonedTimeToUtc(new Date(data[name]), timezone).toISOString() : null; // Convert user's timezone to UTC before saving
      const newValue = dateValue
      if (rowId && formatDate(dateValue) !== formatDate(value)) {
        if (page === 'portfolio') {
          await updateTradeCellByRowId({ row, columnId, newValue, token });

        } if (page === 'WheelIncome') {
          await updateWheelIncomeCellByRowId({ row, columnId, newValue, token, sellingType });

        }
        updateData({ rowIndex, rowId, columnId, value: newValue });
      } else {
        updateData({ rowIndex, rowId, columnId, value: newValue });
      }
    } catch (error) {
      console.error('Error updating date cell:', error);
    }
  };

  useEffect(() => {
    register(name);

    // Convert UTC date to user's timezone before setting it as the value
    setValue(name, value ? utcToZonedTime(new Date(value), timezone) : null);

    if (errors[name]) {
      toast.error(errors[name].message);
    }
  }, [register, setValue, value, name, errors, timezone]);
  // const { value: tradeStatus } = useTradeStatus(row);

  // const backgroundColor = tradeStatus === 'pending' && ['tradeDate', 'expirationDate'].includes(columnId) ? 'bg-yellow-300 border-r-gray-200' : tradeStatus === 'active' && ['tradeDate', 'expirationDate', 'closingDate'].includes(columnId) ? getBgColor(columnId) : tradeStatus !== 'closed' && ['closingDate'].includes(columnId) ? getBgColor(columnId) : '';

  // const backgroundColor = getDateCellStyles(page, columnId, row);
  let backgroundColor = 'bg-transparent'; // Default background color
  if (page === 'portfolio' && columnId === 'expirationDate') {
    const daysToExpiration = calculateDaysToExpiration(value, timezone);
    const { value: tradeStatus } = getTradeStatus(row);

    if (tradeStatus !== 'closed') {
      if (daysToExpiration === 'Expired') {
        backgroundColor = 'bg-gray-400';
      } else if (daysToExpiration === 0) {
        backgroundColor = 'bg-red-500';
      } else if (typeof daysToExpiration === 'number' && daysToExpiration < 7) {
        backgroundColor = 'bg-orange-300';
      }
    }
  }
  if (page === 'WheelIncome') {
    backgroundColor = getBgColor('editableCell')
  }

  return (
    <form
      className={`px-4 h-[28px]  w-full flex items-center ${columnId === 'closingDate' && page === 'portfolio' && 'border-l-[6px] border-gray-400  border-gray-400 '} justify-center ${backgroundColor}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-1 items-center  justify-start'>
        <div className='w-4 h-4'>
          <MdCalendarMonth className='w-4 h-4' />
        </div>
        {/* <DatePicker
          id={name}
          selected={value ? utcToZonedTime(new Date(value), timezone) : null} // Convert the UTC date to local timezone
          placeholderText="DD-MM-YY"
          isClearable
          // withPortal
          clearButtonClassName=''
          onChange={(date) => {
            // Convert the date from local timezone to UTC before setting it
            const utcDate = date ? zonedTimeToUtc(date, timezone) : null
            setValue(name, utcDate);
            if (utcDate === null)
              handleSubmit(onSubmit)();
          }}
          onSelect={handleSubmit(onSubmit)}
          // disabled={!isDateInputEnabled}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          className={`${errors[name] ? 'outline-red-600' : 'outline-[#3e64de]'}
      bg-transparent w-full flex-1 rounded-md border-none z-50 focus:outline-none text-center`}
          onBlur={handleSubmit(onSubmit)}
          dateFormat="dd MMM ''yy"
        /> */}
        <DatePicker
          id={name}
          selected={value ? utcToZonedTime(new Date(value), timezone) : null}
          placeholderText="DD-MM-YY"
          isClearable={page === 'BreakEven' && (row.index === 0 || row.index === 1) ? false : true}
          onChange={(date) => {
            if (date) {
              // Directly construct a UTC format string for the selected date
              const isoDateUTC = formatISO(date, { representation: 'date' }) + 'T00:00:00.000Z';

              setValue(name, isoDateUTC, { shouldValidate: true });
              handleSubmit(onSubmit)();
            } else {
              setValue(name, null, { shouldValidate: true });
              handleSubmit(onSubmit)();
            }
          }}

          onSelect={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            // Consider allowing certain keys if direct text input is enabled
          }}
          onBlur={handleSubmit(onSubmit)}
          dateFormat="dd MMM ''yy"
          disabled={page === 'BreakEven' && (row.index === 0 || row.index === 1)}
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={1}
          // scrollableYearDropdown
          minDate={new Date(new Date().getFullYear() - 10, 0, 1)}
          maxDate={new Date(new Date().getFullYear(), 11, 31)}

          dropdownMode="select" // Enables navigation in dropdown mode
          className={`${errors[name] ? 'outline-red-600' : 'outline-[#3e64de]'}
    bg-transparent w-full flex-1 rounded-md border-none z-50 focus:outline-none text-center`}
        />

      </div>

      {errors[name] && (
        <span className='flex-shrink-0 flex-grow-0'>
          {errors[name].message}
        </span>
      )}
    </form>

  );
};

export const TickerCell = ({
  updateData,
  columnId,
  row,
  page,
  sellingType
}) => {
  const { logout, token } = useAuth();
  const rowId = row.original._id
  const rowIndex = row.index
  const value = row.getValue(columnId)
  const name = `ticker-${columnId}-${rowId || rowIndex}`;
  // const schema = yup.object({
  //   [name]: yup
  //     .string()
  //     .required('Ticker is required')
  //     .max(12, 'Ticker must be at most 12 characters'),
  // });
  const schema = yup.object({
    [name]: yup
      .string()
      .required('Ticker is required')
      .max(12, 'Ticker must be at most 12 characters')
      .matches(
        /^[A-Za-z0-9]+:[A-Za-z]+$/, 
        'Ticker must include a colon (:) followed by the exchange in uppercase letters. Please use the ticker search bar feature to get the correct ticker.'
      ), // Ensure it includes a colon and a valid exchanger
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   [name]: value,
    // },
  });
  // const { value: tradeStatus } = useTradeStatus(row); // Using the custom hook to get the trade status

  // const backgroundColor = tradeStatus === 'pending' ? 'bg-yellow-300' : '';

  const backgroundColor = getTickerStyles(page, columnId, row);
  const onSubmit = async (data) => {
    try {
      const newValue = data[name];
      if (rowId && newValue !== value) {
        switch (page) {
          case 'portfolio':
            await updateTradeCellByRowId({ row, columnId, newValue, token });
            break;
          case 'wheelIncome':
            await updateWheelIncomeCellByRowId({
              row, columnId, newValue, token,
              sellingType
            });
            break;
          case 'watchList':
            await updateWatchCellByRowId({ rowId, columnId, newValue, token });
            break;
          default:
            break;
        }

        updateData({ rowIndex, rowId, columnId, value: newValue });
      } else {
        updateData({ rowIndex, rowId, columnId, value: newValue });
      }
    } catch (error) {
      console.error('Error updating ticker cell:', error);

      if (error.response.data.error === 'Invalid token.') logout();
    }
  };

  useEffect(() => {
    setValue(name, value);
  }, [value, setValue, name]);

  return (
    <form
      className={`px-4 py-1 h-[28px] w-full ${backgroundColor} flex items-center`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input
        {...register(name)}
        onBlur={handleSubmit(onSubmit)}
        className={`${errors[name] ? 'outline-red-600' : 'outline-[#3e64de]'
          } bg-transparent w-full autofill:!bg-transparent text-center uppercase`}
        id={name}
        placeholder='Ticker'
      />
      {errors[name] && <ErrorIcon type='error' name={name} message={errors[name].message} />}
    </form>
  );
};

export const StatusCell = ({ row }) => {
  const { value, color, tooltip } = getTradeStatus(row);

  return (
    <div title={tooltip} className='px-4 py-1 w-full text-start flex items-center justify-start'>
      <FaCircle className='w-4 h-4 mr-1' style={{ color: color }} />
      {value}
    </div>
  );
};

export const FinalPLCell = ({ columnId, updateData, row, page }) => {
  const { logout, token } = useAuth();
  const rowId = row.original._id;
  const rowIndex = row.index;
  const value = row.getValue(columnId);
  const premium = page === 'portfolio' ? row.getValue('premium') : row.original.premium;
  const closingPremium = page === 'portfolio' ? row.getValue('closingPremium') : row.original.closingPremium;
  const numberOfOptions = row.getValue('numberOfOptions');
  const tradeStatus = page === 'portfolio' && getTradeStatus(row);
  const isClosed = tradeStatus.value === 'closed';

  const [currentValue, setCurrentValue] = useState(value);
  const [manualOverride, setManualOverride] = useState(row.original.finalPLOverride);

  const name = `finalPL-${columnId}-${rowId || rowIndex}`;
  const schema = yup.object({
    [name]: yup.number().required('Final P/L is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  let calculatedFinalPL = calculateFinalPL(premium, closingPremium, numberOfOptions);

  useEffect(() => {
    register(name);
    setValue(name, currentValue);
  }, [register, setValue, name, currentValue]);

  useEffect(() => {
    if (page === 'portfolio' && !manualOverride) {
      setCurrentValue(calculatedFinalPL);
    }

  }, [value, premium, closingPremium, numberOfOptions, page, manualOverride, calculatedFinalPL]);


  const handleValueChange = useCallback(
    async (newValue) => {
      setCurrentValue(newValue);
      if (rowId && newValue !== value) {
        try {
          if (page === 'portfolio') {
            await updateTradeCellByRowId({ row, columnId, newValue, token });
            setManualOverride(true)
            setCurrentValue(newValue)
          }
          updateData({ rowIndex, rowId, columnId, value: newValue });
        } catch (error) {
          console.error('Error updating number cell:', error);
          if (error.response?.data?.error === 'Invalid token.') logout();
        }
      } else if (!rowId && newValue !== value) {
        updateData({ rowIndex, rowId, columnId, value: newValue });
      }
    },
    [rowId, rowIndex, columnId, value, token, updateData, page, logout]
  );

  // const resetToAutomatic = () => {
  //   setCurrentValue(calculatedFinalPL);
  //   setManualOverride(false);
  //   handleValueChange(calculatedFinalPL);
  // };

  const backgroundColor = getFinalPLStyles(page, columnId, row);

  return (
    <form
      className={`w-full px-4 py-1 flex items-center justify-end text-end ${backgroundColor}`}
      onSubmit={handleSubmit((data) => handleValueChange(data[name]))}
    >
      <div className="w-full inline-flex items-center">
        <NumericFormat
          id={name}
          value={page === 'portfolio' && isClosed ? currentValue : page === 'history' ? currentValue : null}
          type="text"
          decimalScale={page === 'history' ? 0 : 2}
          prefix={'$ '}
          fixedDecimalScale
          allowNegative
          thousandSeparator=","
          className={`${errors[name] ? 'outline-red-600' : 'outline-[#3e64de]'
            } bg-transparent text-end w-full`}
          onBlur={handleSubmit((data) => handleValueChange(data[name]))}
          placeholder={'Add Final P/L'}
          onValueChange={({ value }) => setCurrentValue(value)}
        />
        {/* {manualOverride && currentValue !== calculatedFinalPL && (
          <button type="button" className="ml-2 text-gray-500" onClick={resetToAutomatic} title="Reset to automatic">
            <FaUndo />
          </button>
        )}
        {!manualOverride && currentValue === calculatedFinalPL && (
          <div  className="ml-2 text-gray-500"  title="Reset to automatic">
            <FaLock />
          </div>
        )} */}
      </div>
      {errors[name] && <ErrorIcon name={name} type="number" message={errors[name].message} />}
    </form>
  );
};
// export const FinalPLCell = ({

//   columnId,
//   updateData,
//   row,
//   page
// }) => {

//   const { logout, token } = useAuth();
//   const rowId = row.original._id
//   const rowIndex = row.index
//   const value = row.getValue(columnId)
//   const premium = page === 'portfolio' ? row.getValue('premium') : row.original.premium;
//   const closingPremium = page === 'portfolio' ? row.getValue('closingPremium') : row.original.closingPremium;
//   const numberOfOptions = row.getValue('numberOfOptions');
//   const tradeStatus = page === 'portfolio' && getTradeStatus(row);
//   const isClosed = tradeStatus.value === 'closed';

//   const [currentValue, setCurrentValue] = useState(value);
//   const name = `finalPL-${columnId}-${rowId || rowIndex}`;
//   const schema = yup.object({
//     [name]: yup
//       .number()
//       .required('Final P/L is required')
//     // .positive('Final P/L must be positive'),
//   });

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     setValue,
//   } = useForm({
//     resolver: yupResolver(schema),
//   });

//   const handleValueChange = (newValue) => {
//     setCurrentValue(newValue);
//     onSubmit({
//       row,
//       columnId,
//       newValue: newValue,
//       token,
//       updateData,
//       currentValue: value,
//     });
//   };

//   useEffect(() => {
//     register(name);
//     setValue(name, currentValue);
//   }, [register, setValue, name, currentValue]);

//   // Calculate finalPL for the portfolio page but do not update the backend
//   useEffect(() => {
//     if (page === 'portfolio' && (value === null || value === 0)) {
//       const finalPL = calculateFinalPL(premium, closingPremium, numberOfOptions);
//       setCurrentValue(finalPL);
//     }
//   }, [value, premium, closingPremium, numberOfOptions, page]);
//   const backgroundColor = getFinalPLStyles(page, columnId, row);
//   return (
//     <form
//       className={`w-full px-4 py-1 flex items-center justify-end text-end ${columnId === 'finalPL' && page === 'portfolio' && 'border-r-[6px] border-gray-400 '} ${backgroundColor}`}
//       onSubmit={handleSubmit(data => handleValueChange(data[name]))}
//     >
//       <div className=" w-full inline-flex items-center">
//         <NumericFormat
//           id={name}
//           value={page === 'portfolio' && isClosed ? currentValue : page === 'history' ? currentValue : null}
//           type="text"
//           decimalScale={page === 'history' ? 0 : 2}
//           prefix={'$ '}
//           fixedDecimalScale
//           allowNegative
//           thousandSeparator=","
//           className={`${errors[name] ? 'outline-red-600' : 'outline-[#3e64de]'}  bg-transparent text-end w-full`}
//           onBlur={handleSubmit(data => handleValueChange(data[name]))}
//           placeholder={'Add Final P/L'}
//           onValueChange={({ value }) => setCurrentValue(value)}
//         />
//       </div>
//       {
//         errors[name] && (
//           <ErrorIcon name={name} type='number' message={errors[name].message} />
//         )
//       }
//     </form >
//   );
// };






export const NumberCell = ({

  updateData,
  // rowId,
  getValue,
  columnId,
  row,
  page,
  sellingType
}) => {
  const { token } = useAuth()
  const rowId = row.original._id
  const rowIndex = row.index
  const value = row.getValue(columnId)
  const [localValue, setLocalValue] = useState(value || 0);
  const [isFocused, setIsFocused] = useState(false);


  const { logout } = useAuth();
  const name = `number-${columnId}-${rowId || rowIndex}`;

  const {
    register,
    handleSubmit,
    formState: { errors },

    setValue,
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const handleFocus = () => {
    // Clear the input if the value is 0
    setIsFocused(true)
    if (localValue === 0 || localValue === '0' || localValue === '0.00') {
      setLocalValue('');
    }
  };

  const onSubmit = useCallback(async (data) => {
    setIsFocused(false)
    let newValue = data[name];

    // Check if the value starts with a dot and prepend 0 if necessary
    if (typeof newValue === 'string' && newValue.startsWith('.')) {
      newValue = '0' + newValue;
    }
    // Parse the newValue to a float

    try {
      if (newValue === '' || isNaN(newValue)) {
        setLocalValue(0);
        newValue = 0
      }
      newValue = parseFloat(newValue);
      if (rowId && newValue !== value && newValue !== '') {
        if (page === 'portfolio') {

          await updateTradeCellByRowId({ row, columnId, newValue, token });

        } if (page === 'WheelIncome') {
          await updateWheelIncomeCellByRowId({
            row, columnId, newValue, token,
            sellingType
          });

        }
        updateData({ rowIndex, rowId, columnId, value: newValue });

        // Conditionally update premiumMarketPrice in local state if premium was updated
        if (columnId === 'premium' && !getValue('premiumMarketPrice')) {
          updateData({ rowIndex, rowId, columnId: 'premiumMarketPrice', value: newValue });
        }
      }
      else if (!rowId && newValue !== value && newValue !== '' && newValue !== '') {
        updateData({ rowIndex, rowId, columnId, value: newValue });
      }
    } catch (error) {
      console.error('Error updating number cell:', error);

      if (error.response.data.error === 'Invalid token.') logout();
    }

  }, [rowId, rowIndex, columnId, value, token, updateData, name, logout]);

  // Effect to sync localValue with value
  useEffect(() => {
    setLocalValue(value || 0);
  }, []);


  useEffect(() => {
    setValue(name, localValue);
  }, [localValue, columnId, name])

  register(name);
  const { value: tradeStatus } = useTradeStatus({ getValue }); // Using the custom hook to get the trade status


  // const backgroundColor = tradeStatus === 'pending' && ['strike', 'premium', 'numberOfOptions'].includes(columnId) ? 'bg-yellow-300 border border-r-gray-200' : 'bg-transparent';
  const backgroundColor = tradeStatus === 'pending' && ['strike', 'premium', 'numberOfOptions'].includes(columnId) ? 'bg-yellow-300 border-r-gray-200' : tradeStatus === 'active' && ['strike', 'premium', 'numberOfOptions', 'premiumMarketPrice', 'closingPremium', 'breakEvenStrike'].includes(columnId) ? getBgColor(columnId) : tradeStatus !== 'closed' && ['premiumMarketPrice', 'closingPremium'].includes(columnId) ? getBgColor(columnId) : 'bg-transparent';
  return (
    <form
      className={`px-4 h-[28px] w-full flex items-center justify-end ${page === 'WheelIncome' ? getBgColor('editableCell') : page === 'BreakEven' && (row.index !== 0 && row.index !== 1) ? getBgColor(columnId) : backgroundColor}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className=" w-full inline-flex items-center">
        <NumericFormat
          id={name}
          value={
            ((columnId === 'closingPremium' || columnId === 'premiumMarketPrice' || columnId === 'premium' || columnId === 'breakEvenFinalPL') && (value === null || value === undefined))
              ? ''
              : localValue
          }
          type="text"
          prefix={`${['premium', 'premiumMarketPrice', 'closingPremium', 'stockPriceAtTrade', 'sellingCallStrikePrice', 'WheelOptionPremium', 'breakEvenStrike', 'breakEvenFinalPL', 'breakEvenTotal'].includes(columnId) ? (columnId === 'closingPremium' && value === 0) ? '' : '$ ' : ''}`}
          decimalScale={2}
          fixedDecimalScale={['strike', 'numberOfOptions', 'optionsToTrade', 'WheelIncomeCallNumberOfShares', 'WheelIncomeCallOptionsToTrade', 'breakEvenShares'].includes(columnId) || isFocused ? false : true}
          allowNegative={false}
          thousandSeparator=","
          className={`${errors[name] ? 'outline-red-600' : 'outline-[#3e64de]'} bg-transparent ${['premium', 'premiumMarketPrice', 'totalValueIfAssigned', 'closingPremium', 'stockPriceAtTrade', 'WheelOptionPremium', 'breakEvenFinalPL', 'breakEvenStrike'].includes(columnId) ? 'text-end' : 'text-center'} w-full`}
          // onFocus={() => setShowIcons(true)}
          onBlur={handleSubmit(onSubmit)}
          onFocus={handleFocus}
          disabled={page === 'BreakEven' && (row.index === 0 || row.index === 1)}
          placeholder={
            (columnId === 'closingPremium' && value === 0)
              ? 'Add Premium'
              : page === 'BreakEven' && row.index === 0 ? '' : `Add ${columnId
                .replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter
                .trim() // Remove any leading or trailing spaces
                .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
                }`
          }
          // allowLeadingZeros={false}
          onValueChange={({ value }) => {
            let formattedValue = value;

            if (formattedValue === '.') {
              // Handle the case where the user just types a dot
              setLocalValue('0.');
            } else if (formattedValue.startsWith('0') && formattedValue.length > 1 && formattedValue[1] !== '.') {
              // Handle leading zero followed by a non-dot character (e.g., 01, 02)
              const numericValue = parseFloat(formattedValue);
              setLocalValue(numericValue.toString());
            } else {
              const numericValue = parseFloat(formattedValue);
              if (!isNaN(numericValue)) {
                setLocalValue(formattedValue);
              } else {
                setLocalValue('');
              }
            }
          }}


        />
      </div>
      {errors[name] && (
        <ErrorIcon name={name} type='number' message={errors[name].message} />
      )}
    </form>
  );
};

export const handlePortfolioSave = async ({
  data,
  token,
  setData,
  setNewRow,
  logout,
}) => {
  try {
    // Assuming the new trade data is the first item in the data array
    const newTradeData = data[0];
    if (newTradeData.closingTradeType && newTradeData.closingDate === null) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Create a new date object and convert it to UTC using the user's timezone
      const tradeDate = zonedTimeToUtc(new Date(), timezone);
      newTradeData.closingDate = tradeDate
    }
    // Calling the createTrade service to save the new trade
    const savedTrade = await createTrade(newTradeData, token);

    // Updating the state with the saved trade data (if needed)
    setData((oldData) => [savedTrade, ...oldData.slice(1)]);
    // setSort(false)
    // Resetting the newRow state
    setNewRow(false);
  } catch (error) {
    console.error('Error saving the new trade:', error);

    if (error.response.data.error === 'Invalid token.') logout();
    // You may want to handle the error more gracefully, depending on your UI/UX requirements
  }
};
export const handleWheelIncomeSave = async ({
  data,
  token,
  setData,
  setNewRow,
  logout,
  WheelType
}) => {
  try {
    // Assuming the new trade data is the first item in the data array
    const newTradeData = data[0];
    // Calling the createTrade service to save the new trade
    const savedTrade = await createWheelTrade(newTradeData, token, WheelType);

    // Updating the state with the saved trade data (if needed)
    setData((oldData) => [savedTrade, ...oldData.slice(1)]);
    // setSort(false)
    // Resetting the newRow state
    setNewRow(false);
  } catch (error) {
    console.error('Error saving the new trade:', error);

    if (error.response.data.error === 'Invalid token.') logout();
    // You may want to handle the error more gracefully, depending on your UI/UX requirements
  }
};


export const handleHistorySave = async ({
  data,
  token,
  setData,
  setNewRow,
  logout,
}) => {
  try {
    const newTradeData = data[0]; // Assuming the first item is the new trade to be saved

    // Save the new trade using your service
    const savedTrade = await addManualTrade(newTradeData, token);

    // Extract group ID from the trade date
    const groupIdentifier = extractGroupIdFromTradeDate(savedTrade.closingDate);

    setData((oldData) => {
      // First, filter out the newItem (assuming it's identified by isNew property or an identifier)
      const filteredData = oldData.filter(item => item.id !== newTradeData.id);
      const groupIndex = filteredData.findIndex(
        (item) => item.id === groupIdentifier && item.isGroup
      );

      if (groupIndex !== -1) {
        // If the group exists, add the new trade to its subRows and remove it from the root
        const newData = [...filteredData];
        newData[groupIndex].subRows = [
          ...newData[groupIndex].subRows,
          savedTrade // Ensure savedTrade has all necessary properties to match the existing items in subRows
        ].sort((a, b) => new Date(a.closingDate) - new Date(b.closingDate));

        return newData;
      } else {
        // If the group does not exist, create a new group and include the new trade
        const newGroup = {
          id: groupIdentifier, // Ensure this matches your group identifying scheme
          isGroup: true,
          groupTitle: formatGroupTitle(savedTrade.closingDate), // A function to format the title based on the trade date or other criteria
          subRows: [savedTrade],
          finalPL: savedTrade.finalPL, // Since this is a new group with only one trade, its finalPL is just the trade's finalPL
        };

        // Return the array with the new group added
        return [...filteredData, newGroup].sort((a, b) => {
          // Sort by some criteria, for example, date
          if (a.isGroup && b.isGroup) {
            return new Date(a.groupTitle) - new Date(b.groupTitle);
          }
          return 0;
        });
      }
    });

    // Reset the newRow state
    setNewRow(false);
  } catch (error) {
    console.error('Error saving the new trade:', error);
    if (error.response && error.response.data.error === 'Invalid token.') {
      logout();
    }
    // Optionally, handle the error more gracefully
  }
};


// Helper function to extract the group ID from trade date
const extractGroupIdFromTradeDate = (tradeDate) => {
  const date = new Date(tradeDate);
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  return `${month} ${year}`;
};

export const handleWatchListSave = async ({
  data,
  token,
  setData,
  setNewRow,
  logout,
}) => {
  try {
    // Assuming the new trade data is the first item in the data array
    const newTradeData = data[0];
    // Calling the createTrade service to save the new trade
    const savedTrade = await addToWatchList(newTradeData, token);

    // Updating the state with the saved trade data (if needed)
    setData((oldData) => [savedTrade, ...oldData.slice(1)]);
    // setSort(false)
    // Resetting the newRow state
    setNewRow(false);
  } catch (error) {
    console.error('Error saving the new trade:', error);

    if (error.response.data.error === 'Invalid token.') logout();
    // You may want to handle the error more gracefully, depending on your UI/UX requirements
  }
};

export const toggleDecayColumns = (selectedDecays, allColumns) => {
  const updatedVisibility = {};

  allColumns.forEach((columnGroup) => {
    columnGroup.columns.forEach((column) => {
      if (column.id.startsWith('decay')) {
        const isDecayColumnVisible = selectedDecays.some((decay) => column.id.includes(`decay${decay}`));
        updatedVisibility[column.id] = isDecayColumnVisible;

        if (column.columns) {
          column.columns.forEach((subColumn) => {
            updatedVisibility[subColumn.id] = isDecayColumnVisible;
          });
        }
      }
    });
  });

  return updatedVisibility;
};

export const handleDeleteConfirm = async ({
  modalState,
  setModalState,
  token,
  setData,
  logout,
  page
}) => {
  try {
    // Retrieve the trade ID from modalState
    const tradeId = modalState.trade.original?._id;

    modalState.trade.original.isManualEntry ? await deleteManualTradeById(tradeId, token) : await deleteTradeById(tradeId, token)
    // Call the deleteTradeById service function with the trade ID

    if (page === 'history') {
      setData((prevData) => prevData.map(monthGroup => ({
        ...monthGroup,
        // Filter out the deleted trade from subRows of the monthGroup
        subRows: monthGroup.subRows.filter(trade => trade._id !== tradeId)
      })).filter(monthGroup => monthGroup.subRows.length > 0));
    } else
      // Update the local state to remove the deleted trade (if needed)
      setData((prevData) => prevData.filter((trade) => trade._id !== tradeId));

    // Close the modal
    setModalState({ isOpen: false, trade: null });
  } catch (error) {
    console.error('Error deleting the trade:', error);

    if (error.response.data.error === 'Invalid token.') logout();
    // Handle error as needed, e.g., show an error message to the user
  }
};

export const handleBreakEvenOperationDeleteConfirm = async ({
  modalState,
  setModalState,
  token,
  setData,
  logout,
}) => {
  try {
    // Retrieve the operation ID from modalState
    const operationId = modalState.trade.original._id;
    const parentIndex = modalState.trade.parentId; // "0"

    // Call the deleteBreakEvenOperation service function with the operation ID
    await deleteBreakEvenOperation(operationId, token);

    // Update the local state to remove the deleted operation
    setData((prevData) =>
      prevData.map((group, index) => {
        if (`${index}` === parentIndex) {
          return {
            ...group,
            subRows: group.subRows.filter(
              (subRow) => subRow._id !== operationId
            ),
          };
        }
        return group;
      })
    );

    // Close the modal
    setModalState({ isOpen: false, operation: null });
  } catch (error) {
    console.error('Error deleting the operation:', error);

    setModalState({ isOpen: false, operation: null });
    if (error.response?.data?.error === 'Invalid token.') logout();
    // Handle error as needed, e.g., show an error message to the user
  }
};
export const handleWatchListDeleteConfirm = async ({
  modalState,
  setModalState,
  token,
  setData,
  logout,
}) => {
  try {
    // Retrieve the trade ID from modalState
    const itemId = modalState.watchListItem.original?._id;

    // Call the deleteTradeById service function with the trade ID
    await deleteWatchListById(itemId, token);

    // Update the local state to remove the deleted trade (if needed)
    setData((prevData) => prevData.filter((trade) => trade._id !== itemId));

    // Close the modal
    setModalState({ isOpen: false, trade: null });
  } catch (error) {
    console.error('Error deleting the trade:', error);

    if (error.response.data.error === 'Invalid token.') logout();
    // Handle error as needed, e.g., show an error message to the user
  }
};


export const handleWheelIncomeDeleteConfirm = async ({
  modalState,
  setModalState,
  token,
  setData,
  logout,
  sellingType
}) => {
  try {
    // Retrieve the trade ID from modalState
    const itemId = modalState.wheelIncomeItem.original?._id;

    // Call the deleteTradeById service function with the trade ID
    await deleteWheelIncomeById(itemId, token, sellingType);

    // Update the local state to remove the deleted trade (if needed)
    setData((prevData) => prevData.filter((trade) => trade._id !== itemId));

    // Close the modal
    setModalState({ isOpen: false, trade: null });
  } catch (error) {
    console.error('Error deleting the trade:', error);

    if (error.response.data.error === 'Invalid token.') logout();
    // Handle error as needed, e.g., show an error message to the user
  }
};

export const MarketCapCell = ({ value }) => {
  let style = {};
  let displayValue = '';
  // Convert to abbreviated form
  const numValue = parseFloat(value);
  if (numValue >= 1e12) {
    displayValue = `${(numValue / 1e12).toFixed(2)} T`;
  } else if (numValue >= 1e9) {
    displayValue = `${(numValue / 1e9).toFixed(2)} B`;
  } else if (numValue >= 1e6) {
    displayValue = `${(numValue / 1e6).toFixed(2)} M`;
  } else {
    displayValue = `${numValue}`;
  }

  const convertedValue = numValue / 1e6; // Converted to millions for easier comparison

  const tailwindColors = {
    'green-100': '#E7F9E8',
    'green-200': '#A7F3D0',
    'green-400': '#6EE7B7',
    'green-600': '#059669',
    'green-800': '#065F46',
    'green-900': '#064E3B',
  };

  if (convertedValue < 300) {
    style = { backgroundColor: tailwindColors['green-100'], color: 'black' }; // Less than Small Cap
  } else if (convertedValue >= 300 && convertedValue < 2000) {
    style = { backgroundColor: tailwindColors['green-200'], color: 'black' }; // Small Cap
  } else if (convertedValue >= 2000 && convertedValue < 10000) {
    style = { backgroundColor: tailwindColors['green-400'], color: 'black' }; // Mid Cap
  } else if (convertedValue >= 10000 && convertedValue < 100000) {
    style = { backgroundColor: tailwindColors['green-600'], color: 'white' }; // Large Cap
  } else if (convertedValue >= 100000 && convertedValue < 200000) {
    style = { backgroundColor: tailwindColors['green-800'], color: 'white' }; // Extra Large Cap
  } else if (convertedValue >= 200000) {
    style = { backgroundColor: tailwindColors['green-900'], color: 'white' }; // Mega Cap
  }

  return (
    <div
      className='mx-4 y-2 px-2 w-full whitespace-nowrap overflow-hidden text-end rounded text-overflow-ellipsis table-cell'
      style={{
        ...style,
        // minWidth: '50px',
        // maxWidth: '200px',
      }}
    >
      {displayValue}
    </div>
  );
};

